import clsx from 'clsx';
import React, {forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import theme from './theme.module.scss';

export interface IVirtualizedProps {

    className?: string;

    rowHeight: number;

    itemsCount: number;

    renderRow: (itemIndex: number, viewportIndex: number, style?: React.CSSProperties) => JSX.Element;

    viewportWidth?: number | string;

    viewportRows?: number;

    viewportHeight?: number | string;

    overscanItems?: number;

    extraData?: any;

    calculateInitialWidth?: boolean;

    renderHeader?: (scrollTop: number, scrollLeft: number) => JSX.Element;

    renderFooter?: (scrollTop: number, scrollLeft: number) => JSX.Element;

    onScroll?: (scrollTop: number, scrollLeft: number) => void;

    onBeforeScrollEnd?: (scrollTop: number) => void;

    beforeScrollEndOffset?: number;

    perf?: boolean;
}

export interface IVirtualizedState {

}

function calculateViewportHeight(
    viewportRows: number,
    hasHeader: boolean,
    hasFooter: boolean,
    itemsCount: number,
    rowHeight: number
) {
    return Math.min(viewportRows, Number(itemsCount) + (hasHeader ? 1 : 0) + (hasFooter ? 1 : 0)) * rowHeight;
}

const Virtualized = forwardRef(({onScroll, beforeScrollEndOffset = 200, onBeforeScrollEnd, extraData, viewportHeight, className, calculateInitialWidth, itemsCount, rowHeight, viewportWidth, viewportRows, overscanItems = 10, renderRow, renderHeader, renderFooter, perf = false}: IVirtualizedProps, ref: React.RefObject<HTMLDivElement>) => {
    const [[scrollTop, scrollLeft, offsetHeight], setScrollPosition] = useState([0, 0, 0]);
    const [scrollEndOffsetArea, setScrollEndOffsetArea] = useState(false);
    const [calculatedWidth, setCalculatedWidth] = useState(0);
    const viewportRef = useRef<HTMLDivElement>();
    const viewport = ref || viewportRef;
    const containerHeight = useMemo(() => rowHeight * itemsCount, [rowHeight, itemsCount]);

    useEffect(() => {
        setScrollPosition([0, 0, viewport.current.offsetHeight]);
    }, []);
    useEffect(() => {
        if (calculateInitialWidth) {
            setCalculatedWidth(viewport.current.offsetWidth);
        }
    }, []);

    const onViewportScroll = useCallback(() => {
        window.requestAnimationFrame(() => {
            const {scrollTop: st, scrollLeft: sl, offsetHeight: oh, scrollHeight} = viewport.current;
            setScrollPosition([st, sl, oh]);
            onScroll && onScroll(st, sl);

            if (onBeforeScrollEnd) {
                if (scrollHeight - (st + oh) <= beforeScrollEndOffset) {
                    !scrollEndOffsetArea && onBeforeScrollEnd(st);
                    setScrollEndOffsetArea(true);
                } else if (scrollEndOffsetArea) {
                    setScrollEndOffsetArea(false);
                }
            }
        });
    }, [beforeScrollEndOffset, scrollEndOffsetArea]);

    useEffect(() => {
        window.addEventListener('resize', onViewportScroll);

        return () => window.removeEventListener('resize', onViewportScroll);
    }, [onViewportScroll]);

    const calculatedViewportHeight = useMemo(() => {
        return viewportHeight
            ? viewportHeight
            : viewportRows
                ? calculateViewportHeight(viewportRows, !!renderHeader, !!renderFooter, itemsCount, rowHeight)
                : '100%';
    }, [viewportRows, viewportHeight, renderHeader, renderFooter, itemsCount, rowHeight]);

    let startItemIndex = Math.floor(scrollTop / rowHeight) - overscanItems;
    startItemIndex = Math.max(0, startItemIndex);

    let visibleItemsCount = Math.ceil(offsetHeight / rowHeight) + 2 * overscanItems;
    visibleItemsCount = Math.min(itemsCount - startItemIndex, visibleItemsCount);
    visibleItemsCount = Math.max(0, visibleItemsCount);

    const offsetY = startItemIndex * rowHeight;
    const visibleArray = useMemo(() => new Array(visibleItemsCount).fill(null), [visibleItemsCount]);
    const classNames = useMemo(() => clsx(theme.viewport, className), [className]);

    return (
        <div
            data-virtualized-scrolled-x={scrollLeft > 0}
            data-virtualized-scrolled-y={scrollTop > 0}
            className={classNames}
            style={{
                height: calculatedViewportHeight,
                width: viewportWidth || (calculateInitialWidth && calculatedWidth) || ''
            }}
            ref={viewport}
            onScroll={onViewportScroll}
        >
            {renderHeader && (
                <div data-virtualized-header className={theme.header}>{renderHeader(scrollTop, scrollLeft)}</div>
            )}
            <div style={{
                height: containerHeight,
                minWidth: '100%',
                float: 'left',
            }} >
                <div style={perf ? {
                    position: 'relative'
                } : {
                    willChange: 'transform',
                    contain: 'layout style',
                    transform: `translateY(${offsetY}px)`,
                }}>
                    {visibleArray.map((_, viewportIndex) => renderRow(startItemIndex + viewportIndex, viewportIndex, perf ? {
                        willChange: 'transform',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        transform: `translateY(${(startItemIndex + viewportIndex) * rowHeight}px)`
                    } : {}))}
                </div>
            </div>
            {renderFooter && (
                <div data-virtualized-footer className={theme.footer}>{renderFooter(scrollTop, scrollLeft)}</div>
            )}
        </div>
    );
});

export default memo(Virtualized);
