import clsx from 'clsx';
import {forwardRef, memo, useContext} from 'react';
import {isBoolean} from '../../../../stdlib/assert';
import TableContext, {ITableContext} from './TableContext';
import theme from './theme.module.scss';
export interface IColumnProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
    width?: number;
    disableEllipsis?: boolean;
    colKey?: string;
    condition?: boolean;
}

export interface IColumnState {

}

const Column = forwardRef(({className, children, width, disableEllipsis, style, colKey, condition, ...others}: IColumnProps, ref: React.RefObject<HTMLDivElement>): JSX.Element => {
    const context = useContext<ITableContext>(TableContext);

    if (isBoolean(condition) && !condition) {
        return null;
    }

    const columnStyle: React.CSSProperties = {
        ...style,
        width: width || context.minColumnWidth
    };

    return (
        <div
            ref={ref}
            data-column
            {...others}
            style={columnStyle}
            className={clsx(theme.column, className, {
                [theme.ellipsis]: !disableEllipsis
            })}
        >
            {children}
        </div>
    );
});

export default memo(Column);
