import {TableColumn} from '../../../ui/components/ui-virtualized';
import ISite, {ISiteUser} from '../../interfaces/ISite';

function checkInvalidOwnership(site: ISite): boolean {
    if ((site.user as ISiteUser).companyDomain && site.ownedBy) {
        return (site.user as ISiteUser).companyDomain !== site.ownedBy;
    }

    return false;
}

function checkValidOwnership(site: ISite): boolean {
    if ((site.user as ISiteUser).companyDomain && site.ownedBy) {
        return (site.user as ISiteUser).companyDomain === site.ownedBy;
    }

    return false;
}

export const TABLE_COLUMNS: TableColumn<ISite>[] = [
    {title: 'Domain', key: 'domain', defaultWidth: 300, options: {search: {searchKey: 'domain'}, freeze: true, sort: {sortKey: 'domain'}}},
    {title: 'Marketplace Status', key: 'marketplaceApprovalStatus', options: {filter: {filterKey: 'marketplaceApprovalStatus', options: [{value: 'PENDING', label: 'Pending'}, {value: 'APPROVED', label: 'Approved'}, {value: 'REJECTED', label: 'Rejected'}]}, freeze: true, sort: {sortKey: 'marketplaceApprovalStatus'}}},
    {title: 'Ads.txt', key: 'ads.txt', defaultWidth: 250},
    {title: 'Last Scanned Ads.txt', key: 'lastScanned', defaultWidth: 260, options: {sort: {sortKey: 'lastScanned'}}},
    {title: 'Owned By', key: 'ownedBy', defaultWidth: 400, options: {search: {searchKey: 'ownedBy'}, freeze: true, sort: {sortKey: 'ownedBy'}, filter: {filterKey: 'ownedBy', options: [{value: 'ownership_invalid', label: 'Ownership Issue', check: checkInvalidOwnership}, {value: 'ownership_valid', label: 'Ownership Valid', check: checkValidOwnership}]}}},
    {title: 'Last Scanned Owned By', key: 'lastScanOwnedBy', defaultWidth: 250, options: {sort: {sortKey: 'lastScanOwnedBy'}}},
    {title: 'Account', key: 'account', options: {sort: {sortKey: 'user.username'}, search: {searchKey: 'user.username'}}},
    {title: 'Created Date', key: 'created', options: {sort: {sortKey: 'created'}}},
    {title: 'Updated Date', key: 'updated', options: {sort: {sortKey: 'updated'}}},
];

export const CSV_FIELDS = [
    {name: 'ID', key: '_id'},
    {name: 'Domain', key: 'domain'},
    {name: 'Marketplace Approval Status', key: 'marketplaceApprovalStatus'},
    {name: 'Ads Txt Status', key: 'adsTxtStatus'},
    {name: 'Account', key: 'user.username'},
    {name: 'Date Added', key: 'created'},
    {name: 'Date Updated', key: 'updated'},
];