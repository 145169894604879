import {define, singleton} from '@injex/core';
import EntityRequestAdapter from '../../../stdlib/EntityRequestAdapter';
import IAccountMetadata from '../interfaces/IAccountMetadata';

@define()
@singleton()
export class AccountsAPI extends EntityRequestAdapter {
    protected basePathName: string = 'accounts';

    public async me(): Promise<IAccountMetadata> {
        const response = await this.requestWithContext<IAccountMetadata>({
            method: 'get',
            url: this.makeAPIPath('/me')
        });

        return response.data;
    }
}