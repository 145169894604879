import clsx from 'clsx';
import * as ease from 'd3-ease';
import {useCallback, useEffect, useRef} from 'react';
import {animated, useTransition} from 'react-spring';
import useToggle from '../../../../common/hooks/useToggle';
import {isFunction} from '../../../../stdlib/assert';
import theme from './menu.module.scss';

export interface IToggleMenuButtonProps {
    handler: (isOpen: boolean, toggle: (force?: boolean) => void) => JSX.Element;
    children?: any;
    closeOnClickInside?: boolean;
    vertical?: 'top' | 'bottom';
    horizontal?: 'left' | 'right';
    className?: string;
    onToggle?: (isOpen: boolean) => void;
}

ToggleMenuButton.Menu = function ToggleMenuButton_Menu({children}) {
    return (
        <ul>{children}</ul>
    );
};

ToggleMenuButton.MenuItem = function ToggleMenuButton_MenuItem({children, onClick, disabled = false, ...others}) {
    const onItemClick = useCallback((e) => {
        if (disabled) {
            return;
        }

        onClick(e);
    }, [disabled]);
    return (
        <li {...others} onClick={onItemClick} className={clsx({
            [theme.disabled]: disabled
        })}>
            {children}
        </li>
    );
};

function ToggleMenuButton({onToggle, handler, children, className, closeOnClickInside = true, vertical = 'bottom', horizontal = 'left'}: IToggleMenuButtonProps): JSX.Element {
    const [open, toggle] = useToggle(false);

    const transformY = vertical === 'bottom' ? 'translateY(-5px)' : 'translateY(5px)';

    const transition = useTransition(open, {
        from: {opacity: 0, transform: `${transformY} scaleY(0.98)`},
        enter: {opacity: 1, transform: 'translateY(0px) scaleY(1)'},
        leave: {opacity: 0, transform: `${transformY} scaleY(0.98)`},
        reverse: open,
        config: {
            duration: 200,
            easing: ease.easeCubicInOut
        }
    });

    const containerRef = useRef<HTMLDivElement>();

    useEffect(() => {
        onToggle?.(open);
    }, [open]);

    useEffect(() => {
        function onDocumentClick(e) {
            const element = closeOnClickInside ? containerRef.current?.firstChild : containerRef.current;
            if (!element?.contains(e.target) && open) {
                toggle(false);
            }
        }

        if (open) {
            document.addEventListener('click', onDocumentClick);
        }

        return () => document.removeEventListener('click', onDocumentClick);
    }, [open, closeOnClickInside]);

    return (
        <div className={clsx(theme.container, className)} ref={containerRef}>
            {handler(open, toggle)}
            {transition((styles, item) => item && (
                <animated.div className={clsx(theme.menu, theme[vertical], theme[horizontal])} style={styles}>
                    {isFunction(children) ? children(open, toggle) : children}
                </animated.div>
            ))}
        </div>
    );
}

export default ToggleMenuButton;