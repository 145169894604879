import clsx from 'clsx';
import theme from './theme.module.scss';

function ProgressIndicator({absolute = false}): JSX.Element {
    return (
        <div className={clsx(theme.indicator, {
            [theme.absolute]: absolute
        })}></div>
    );
}

export default ProgressIndicator;