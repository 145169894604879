import clsx from 'clsx';
import {forwardRef} from 'react';
import './theme.scss';

export interface IMaterialIconProps extends React.HTMLAttributes<HTMLElement> {
    iconName: string;
    size?: number | string;
}

export default forwardRef(({iconName, className, style, size, ...others}: IMaterialIconProps, ref: React.ForwardedRef<any>) => {
    return (
        <i
            {...others}
            ref={ref}
            style={{fontSize: size, ...style}}
            className={clsx('material-icons-sharp', className)}
        >{iconName}</i>
    );
});