import clsx from 'clsx';
import {Children, memo, useContext, useMemo} from 'react';
import {isNotEmptyString} from '../../../../stdlib/assert';
import TableContext, {ITableContext} from './TableContext';
import theme from './theme.module.scss';
import {isNumber} from './utils';

export interface IRowProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
    index?: number;
}

export interface IRowState {

}

function Row({className, children, index, ...others}: IRowProps): JSX.Element {
    const {freezeColumnKey} = useContext<ITableContext>(TableContext);
    const hasFreezedColumns = useMemo(() => isNotEmptyString(freezeColumnKey), [freezeColumnKey]);
    let freezedColumns = [];
    let content = Children.toArray(children);

    if (hasFreezedColumns) {
        // find the freezed column index by it's key
        let freezeColumnIndex = -1;
        Children.forEach(content, (child: React.ReactElement, idx) => {
            const colKey = child.props.colKey || child.props.column?.key;
            if (colKey && colKey === freezeColumnKey) {
                freezeColumnIndex = idx;
                return;
            }
        });

        if (freezeColumnIndex > -1) {
            freezedColumns = content.slice(0, freezeColumnIndex + 1);
            content = content.slice(freezeColumnIndex + 1);
        }
    }

    return (
        <div
            data-row
            {...others}
            className={clsx(theme.row, className, {
                [theme.even]: isNumber(index) && Boolean(index % 2)
            })}
        >
            {hasFreezedColumns && (
                <div
                    data-sticky-columns-wrapper
                    className={theme.freezedColumnsWrapper}
                >
                    {freezedColumns}
                </div>
            )}
            <div
                data-columns-wrapper
                className={theme.columnsWrapper}
            >
                {content}
            </div>
        </div>
    );
}

export default memo(Row);
