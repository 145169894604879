import {Suspense} from 'react';
import {Navigate, Route, Routes, unstable_HistoryRouter as Router} from 'react-router-dom';
import AccountManagement from '../../account-management/view';
import AMAccess from '../../account-management/view/access';
import AMActivities from '../../account-management/view/activities';
import AMDetails from '../../account-management/view/details';
import Activity from '../../activities/view';
import App from '../../app/view';
import Home from '../../home/view';
import Login from '../../login/view';
import NotFound from '../../not-found/view';
import Onboarding from '../../onboarding/view';
import OBAdsText from '../../onboarding/view/ads-text';
import OBCompanyLegalInfo from '../../onboarding/view/company-legal-info';
import OBSiteList from '../../onboarding/view/site-list';
import OBTestIntegration from '../../onboarding/view/test-integration';
import Reporting from '../../reporting/view';
import useRouterService from '../../router/hooks/useRouterService';
import ScheduledReports from '../../scheduled-reports/view';
import Settings from '../../settings/view';
import SiteList from '../../sites/site-list/view';
import SiteItems from '../../sites/site-list/view/site-items';
import Site from '../../sites/site/view';
import ProgressBanner from '../../ui/components/progress-banner/ProgressBanner';
import Widgets from '../../widgets/view';

function Loading() {
    return (
        <ProgressBanner>Loading, please wait...</ProgressBanner>
    );
}

export default function Root() {
    const routerService = useRouterService();

    return (
        <Suspense fallback={<Loading />}>
            <Router history={routerService.history}>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/onboarding" element={<Onboarding />}>
                        <Route index element={<Navigate to="company-legal-info" />} />
                        <Route path="company-legal-info" element={<OBCompanyLegalInfo />} />
                        <Route path="site-list" element={<OBSiteList />} />
                        <Route path="ads-text" element={<OBAdsText />} />
                        <Route path="test-integration" element={<OBTestIntegration />} />
                    </Route>
                    <Route path="/" element={<App />}>
                        <Route index element={<Home />} />
                        <Route path="activity/:activityId/" element={<Activity />} />
                        <Route path="activity/:activityId/:field" element={<Activity />} />
                        <Route path="reporting" element={<Reporting />} />
                        <Route path="scheduled-reports" element={<ScheduledReports />} />
                        <Route path="inventory">
                            <Route index element={<Navigate to="site-list" />} />
                            <Route path="site-list" element={<SiteList />}>
                                <Route index element={<Navigate to="all" />} />
                                <Route path=":filter" element={<SiteItems />} />
                            </Route>
                            <Route path="widgets" element={<Widgets />} />
                            <Route path="site/:id" element={<Site />} />
                        </Route>
                        <Route path="account-management" element={<AccountManagement />}>
                            <Route index element={<Navigate to="details" />} />
                            <Route path="details" element={<AMDetails />} />
                            <Route path="access" element={<AMAccess />} />
                            <Route path="activities" element={<AMActivities />} />
                        </Route>
                        <Route path="settings" element={<Settings />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </Router>
        </Suspense>
    );
}