import {createContext} from 'react';

export interface ITableContext {
    freezeColumnKey?: string;
    freezRowIndex?: number;
    minColumnWidth?: number;
    maxColumnWidth?: number;
}

export default createContext<ITableContext>({
    freezeColumnKey: null,
    freezRowIndex: null,
    minColumnWidth: null,
    maxColumnWidth: null
});
