import {define, singleton} from '@injex/core';
import EntityRequestAdapter, {ListResults} from '../../../stdlib/EntityRequestAdapter';
import IArticleEntry from '../interfaces/IArticleEntry';

@define('blogAPI')
@singleton()
export class BlogAPI extends EntityRequestAdapter {
    protected basePathName: string = '';

    protected get apiEndpoint(): string {
        return this.env.contentAPI;
    }

    protected get useAuthorizationHeaders(): boolean {
        return false;
    }

    public async getArticles(): Promise<ListResults<IArticleEntry>> {
        const response = await this.requestWithContext<ListResults<IArticleEntry>>({
            method: 'get',
            url: '/public/articles'
        });

        return response.data;
    }
}