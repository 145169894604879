import {define, inject} from '@injex/core';
import dayjs from 'dayjs';
import {isNumber} from 'highcharts';
import {DATE_FIELDS} from '../../../../common/constants';
import DataQuery from '../../../../common/models/DataQuery';
import {isDefined, isNotEmptyString} from '../../../../stdlib/assert';
import {CrudItemParams} from '../../../../stdlib/EntityRequestAdapter';
import {compact, download, get, uniqueBy} from '../../../../stdlib/utils';
import {ColumnsManager} from '../../../ui/components/ui-virtualized';
import {SiteListAPI} from '../../api/siteList.mdl';
import {CSV_FIELDS, TABLE_COLUMNS} from '../common/constants';
import {SiteListFilter, SitesAction} from '../common/enums';
import ISite from '../../interfaces/ISite';
import {unparse} from 'papaparse';
import {observable} from 'mobx';

@define()
export class SiteListModel extends ColumnsManager<ISite> {
    @inject() private siteListAPI: SiteListAPI;

    public sites: DataQuery<ISite[], [filter: SiteListFilter]>;

    protected get data(): any[] {
        return this.sites.data;
    }

    constructor() {
        super({
            persistKey: 'site_list_columns',
        });

        this.setColumns(TABLE_COLUMNS);

        this.sites = new DataQuery(this._fetchSites.bind(this), {defaultValue: [], dataObservType: observable.ref});
    }

    public async updateSite(site: ISite, key: keyof ISite, value) {
        const currentValue = site[key] as never;
        try {
            site[key] = value as never;
            await this.siteListAPI.updateById(site._id, {[key]: value});
        } catch (e) {
            site[key] = currentValue;
        }
    }

    public async downloadCSV() {
        const fields = {};
        for (const field of CSV_FIELDS) {
            fields[field.key] = 1;
        }

        try {
            const response = await this.siteListAPI.getAll({
                page: 1,
                pageSize: 10000,
                fields
            });

            const head = CSV_FIELDS.map((field) => field.name);
            const rows = [];
            while (response.results.length) {
                const item = response.results.shift();
                rows.push(
                    CSV_FIELDS.map((field) => DATE_FIELDS.includes(field.key) ? dayjs(get(item, field.key)).format() : get(item, field.key))
                );
            }

            const content = unparse({
                fields: head,
                data: rows,
            });

            const filename = `vidazoo_sites_${dayjs().format('DD.MM.YY_HHmm')}.csv`;

            download(content, filename);

        } catch (e) {

        }
    }

    public async downloadAdsTxt() {
        try {
            const content = await this.siteListAPI.getAdsTxt();
            if (!content.trim()) {
                throw new Error('No ads.txt found.');
            }

            download(content, 'vidazoo-ads-txt.txt');
        } catch (e) {

        }
    }

    public performAction(siteIds: string[], action: SitesAction) {
        // TODO:
        // the onld desktop client sends a sparate request for each site
        // in the `siteIds` - we should add new endpoints to handle mass
        // items on single request.
        switch (action) {
            case SitesAction.Delete:
                break;
            case SitesAction.ScanAdsText:
                break;
            case SitesAction.ScanOwnedBy:
                break;
            case SitesAction.ApproveMarketplayer:
                break;
            case SitesAction.RejectMarketplace:
                break;
        }
    }

    private async _fetchSites(filter: SiteListFilter) {
        const requestFilter: CrudItemParams<ISite> = {};

        if (filter !== SiteListFilter.ALL) {
            requestFilter.marketplaceApprovalStatus = filter.toUpperCase();
        }

        const response = await this.siteListAPI.getAll({filter: requestFilter});

        return response.results.map((item) => this._makeSitePossibleOwners(item));
    }

    private _makeSitePossibleOwners(item: ISite): ISite {
        if (item.possibleOwners) {
            if (item.possibleOwnersArray?.length) {
                item.possibleOwnersArray = compact(item.possibleOwnersArray.map((owner: any) => {

                    if (!isDefined(owner.domain)) {
                        return null;
                    }

                    return {
                        value: owner.domain,
                        label: `${owner.domain}${isNumber(owner.count) ? ` ${owner.count}` : ''}`
                    };
                }));
            } else {
                const possibleOwnersArray = uniqueBy(compact(Object.keys(item.possibleOwners).map((domain) => {
                    if (!isNotEmptyString(domain) || domain === 'undefined') {
                        return null;
                    }

                    return {
                        value: domain,
                        label: `${domain}${isNumber(item.possibleOwners[domain]) ? ` ${item.possibleOwners[domain]}` : ''}`
                    };
                })), 'value');

                possibleOwnersArray.length && (item.possibleOwnersArray = possibleOwnersArray);
            }
        }

        return item;
    }
}