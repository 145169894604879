import {define, init, inject} from '@injex/core';
import {observable} from 'mobx';
import DataQuery from '../../../common/models/DataQuery';
import {AccountManager} from '../../account/managers/accountManager.mdl';
import {ColumnsManager} from '../../ui/components/ui-virtualized';
import {WidgetsAPI} from '../api/widgets.mdl';
import IWidget from '../interfaces/IWidget';

@define()
export class WidgetsModel extends ColumnsManager<IWidget> {
    @inject() private widgetsAPI: WidgetsAPI;
    @inject() private accountManager: AccountManager;

    public widgets: DataQuery<IWidget[]>;

    protected get data(): any[] {
        return this.widgets.data;
    }

    constructor() {
        super({
            persistKey: 'widgets_columns',
        });

        this.widgets = new DataQuery(this._fetchWidgets.bind(this), {defaultValue: [], dataObservType: observable.ref});
    }

    @init()
    protected initialize() {
        this.setColumns([
            {title: 'Name', key: 'name', defaultWidth: 650, options: {search: {searchKey: 'name'}, freeze: true, sort: {sortKey: 'name'}}},
            {title: 'Active Status', key: 'isActive', options: {filter: {filterKey: 'isActive', options: [{value: true, label: 'Active'}, {value: false, label: 'Not Active'}]}, freeze: true, sort: {sortKey: 'isActive'}}},
            {title: 'Widget ID', key: '_id', defaultWidth: 300},
            {title: 'Activity Name', key: 'publisher', defaultWidth: 300, options: {sort: {sortKey: this._getActivityNameForSort.bind(this)}, search: {searchKey: this._searchActivityName.bind(this)}}},
            {title: 'Created Date', key: 'created', options: {sort: {sortKey: 'created'}}}
        ]);
    }

    private async _fetchWidgets() {
        const response = await this.widgetsAPI.getAll({});

        return response.results;
    }

    private _getActivityNameForSort(item: IWidget): string {
        return this.accountManager.activityNameByPublisherIds.get(item.publisher);
    }

    private _searchActivityName(item: IWidget, query: string): boolean {
        const activityName = this.accountManager.activityNameByPublisherIds.get(item.publisher);

        if (!activityName) {
            return false;
        }

        return activityName.toLowerCase().includes(query);
    }
}