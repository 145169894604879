import clsx from 'clsx';
import {memo, useCallback, useContext, useEffect, useRef, useState} from 'react';
import Column, {IColumnProps} from './Column';
import ResizeContext, {IResizeContext} from './ResizeContext';
import theme from './theme.module.scss';

export interface IResizableColumnProps extends IColumnProps {
    colKey: string;
    ref?: React.RefObject<HTMLDivElement>;
    condition?: boolean;
}

export interface IResizableColumnState {
    mouseDown: boolean;
}

function ResizableColumn({children, className, ref, colKey, ...others}: IResizableColumnProps): JSX.Element {
    const column = useRef<HTMLDivElement>(ref?.current);
    const context = useContext<IResizeContext>(ResizeContext);
    const [resizeValue, setResizeValue] = useState({
        startX: 0,
        clientX: -1,
        mouseDown: false
    });

    const calculateNextWidth = useCallback((clientX: number, resizeEnabled: boolean) => {
        window.requestAnimationFrame(() => {
            const parentLeft = column.current.getBoundingClientRect().left;
            const {startX} = resizeValue;
            const left = parentLeft;
            const width = (clientX - parentLeft) + startX;

            context.setResizeHandler(resizeEnabled, {left, width, colKey});
        });
    }, [resizeValue]);

    const onMouseDown = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        const parentRect = column.current.getBoundingClientRect();
        const clientX = e.clientX;

        setResizeValue({
            startX: parentRect.left + parentRect.width - clientX,
            mouseDown: true,
            clientX,
        });

        return false;

    }, [column]);

    const onMouseMove = useCallback((e: MouseEvent) => {
        window.requestAnimationFrame(() => {
            const clientX = e.clientX;
            setResizeValue((prev) => ({
                ...prev,
                clientX
            }));
        });
    }, [column]);

    const onMouseUp = useCallback((e: MouseEvent) => {
        setResizeValue((prev) => ({
            ...prev,
            mouseDown: false
        }));
    }, []);

    useEffect(() => {
        if (resizeValue.clientX > -1) {
            calculateNextWidth(resizeValue.clientX, resizeValue.mouseDown);
        }
    }, [resizeValue]);

    useEffect(() => {
        if (resizeValue.mouseDown) {
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);

            return () => {
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('mouseup', onMouseUp);
            };
        }
    }, [resizeValue.mouseDown, column]);

    return (
        <Column
            {...others}
            ref={column}
            className={clsx(theme.resizableColumn, className)}
        >
            {children}
            <div className={clsx(theme.resizeHandler, {
                [theme.active]: resizeValue.mouseDown
            })} onMouseDown={onMouseDown} />
        </Column>
    );
}

export default memo(ResizableColumn);
