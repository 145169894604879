import clsx from 'clsx';
import {useCallback, useEffect, useMemo} from 'react';
import useTableRowSelection from '../../../../common/hooks/useTableRowSelection';
import useToggle from '../../../../common/hooks/useToggle';
import {isString} from '../../../../stdlib/assert';
import MaterialIcon from '../material-icon/MaterialIcon';
import TextInput from '../text-input/TextInput';
import ToggleMenuButton from '../toggle-menu-button/ToggleMenuButton';
import {ColumnsManager} from './columnsManager.mdl';
import ResizableColumn from './ResizableColumn';
import theme from './theme.module.scss';
import {TableColumn} from './utils';

export interface IHeaderColumnProps {
    colKey: string;
    columnsManager: ColumnsManager<any>;
    condition?: boolean;
    disableFilter?: boolean;
    disableSearch?: boolean;
    disableSort?: boolean;
}

function HeaderSearch({onSearch, query, placeholder}) {

    const onChange = useCallback((e) => {
        onSearch(e.target.value);
    }, []);

    const onClear = useCallback(() => {
        onSearch('');
    }, []);

    return (
        <div className={theme.columnOptions}>
            <div className={theme.legend}>
                <div>Search</div>
                <button type="button" className={clsx('bg-none border-none p-0 ml-auto text-vdzblue-300', {
                    'invisible': !query
                })} onClick={onClear}>Clear</button>
            </div>
            <ul>
                <li>
                    <TextInput placeholder={placeholder} className={theme.searchInput} autoFocus value={query} onChange={onChange} />
                </li>
            </ul>
        </div>
    );
}

function HeaderFilter({filter, onFilter, values}) {
    const allItems = useMemo(() => filter.options.map((item) => isString(item) ? item : item.value), [filter]);
    const {isItemSelected, toggleItem, selectedItems} = useTableRowSelection(allItems, false, values);

    useEffect(() => {
        if (values.length !== selectedItems.length) {
            onFilter(selectedItems);
        }
    }, [selectedItems, values]);

    return (
        <div className={theme.columnOptions}>
            <div className={theme.legend}>Filter</div>
            <ul>
                {filter.options.map((item) => {
                    const {label, value} = isString(item) ? {label: item, value: item} : item;

                    return (
                        <li key={value}>
                            <label className="flex items-center cursor-pointer">
                                <span className="truncate mr-8">{label}</span>
                                <input type="checkbox" className="flex ml-auto" checked={isItemSelected(value)} onChange={() => toggleItem(value)} />
                            </label>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

function HeaderFreeze({onFreezeToggle, freezed = false, toggleMenu}) {
    const [active, toggle] = useToggle(freezed);

    const onChange = useCallback(() => {
        const next = !active;
        toggle(next);
        onFreezeToggle(next);
        toggleMenu(false);
    }, [active]);

    return (
        <div className={theme.columnOptions}>
            <div className={theme.legend}>Freeze</div>
            <ul>
                <li>
                    <label className="flex items-center cursor-pointer">
                        <span className="truncate mr-8">Freeze Column</span>
                        <input type="checkbox" checked={active} onChange={onChange} className="flex ml-auto" />
                    </label>
                </li>
            </ul>
        </div>
    );
}

function HeaderSort({onSort, isSorted, sortDir}) {
    const onSortClick = useCallback((dir) => {
        onSort(dir);
    }, []);

    return (
        <div className={theme.columnOptions}>
            <div className={theme.legend}>Sort</div>
            <ul>
                <li className={clsx(theme.sortOption, {[theme.sorted]: isSorted && sortDir === 1})} onClick={() => onSortClick(1)}>
                    Ascending <MaterialIcon iconName="arrow_upward" />
                </li>
                <li className={clsx(theme.sortOption, {[theme.sorted]: isSorted && sortDir === -1})} onClick={() => onSortClick(-1)}>
                    Descending <MaterialIcon iconName="arrow_downward" />
                </li>
            </ul>
        </div>
    );
}

function HeaderColumn({colKey, columnsManager, condition, disableFilter, disableSearch, disableSort}: IHeaderColumnProps): JSX.Element {
    const column = useMemo<TableColumn<any>>(() => columnsManager.getColumn(colKey), [colKey]);

    const onColumnSearch = useCallback((query: string) => {
        columnsManager.setSearch(column, query);
    }, []);

    const onColumnFilter = useCallback((selectedItems: string[]) => {
        columnsManager.setFilter(column, selectedItems);
    }, []);

    const onColumnFreezeToggle = useCallback((freezed: boolean) => {
        columnsManager.setFreeze(column, freezed);
    }, []);

    const onColumnSort = useCallback((sortDir: 1 | -1) => {
        columnsManager.setSort(column, sortDir);
    }, []);

    const hasAnyOptionActive = Boolean(columnsManager.getColumnSearchQuery(column) || columnsManager.getColumnFilterValues(column).length || columnsManager.freeze === column.key || columnsManager.sort === column.key);

    return (
        <ResizableColumn condition={condition} disableEllipsis className={clsx(theme.columm, {
            [theme.filtered]: hasAnyOptionActive
        })} colKey={column.key} width={columnsManager.getWidth(column.key)}>
            <div className="flex items-center">
                <span className={theme.title}>{column.title}</span>
                {column.options ? (
                    <div className="ml-auto">
                        <ToggleMenuButton closeOnClickInside={false} className="ml-auto self-center" handler={(isOpen, toggle) =>
                            <button className="flex" onClick={() => toggle()}>
                                <MaterialIcon iconName={hasAnyOptionActive ? 'filter_list' : isOpen ? 'expand_less' : 'expand_more'} className="flex" size={16} />
                            </button>}
                        >
                            {(_isOpen, toggle) => (
                                <div className={theme.headerMenu}>
                                    {column.options?.search && !disableSearch ? <HeaderSearch onSearch={onColumnSearch} placeholder={`Search ${column.title}...`} query={columnsManager.getColumnSearchQuery(column)} /> : null}
                                    {column.options?.filter && !disableFilter ? <HeaderFilter onFilter={onColumnFilter} filter={column.options.filter} values={columnsManager.getColumnFilterValues(column)} /> : null}
                                    {column.options?.freeze ? <HeaderFreeze onFreezeToggle={onColumnFreezeToggle} freezed={columnsManager.freeze === column.key} toggleMenu={toggle} /> : null}
                                    {column.options?.sort && !disableSort ? <HeaderSort onSort={onColumnSort} isSorted={columnsManager.sort === column.key} sortDir={columnsManager.sortDir} /> : null}
                                </div>
                            )}
                        </ToggleMenuButton>
                    </div>
                ) : null}
            </div>
        </ResizableColumn>
    );
}

export default HeaderColumn;