import {useCallback, useEffect, useMemo, useState} from 'react';

export type TableRowSelectionHook = {
    isItemSelected: (itemKey: string) => boolean;
    isAllSelected: boolean;
    isPartialSelected: boolean;
    toggleAllItems: () => void;
    toggleItem: (itemKey: string) => void;
    selectedItems: string[];
    clearSelection: () => void;
};

export default function useTableRowSelection(allItemKeys: string[], singleSelection: boolean, initialSelection: string[] = []): TableRowSelectionHook {
    const [selectedItems, setSelectedItems] = useState(initialSelection);

    // if all item keys changed, we need to reset the
    // selectedItems and remove item that do no exists
    // anymore. this is for cases when we filter the
    // items in the list.
    useEffect(() => {
        setSelectedItems((prevSelectedItems) => {
            if (!prevSelectedItems.length) {
                return prevSelectedItems;
            }

            const next = [];

            for (let i = 0, len = prevSelectedItems.length; i < len; i++) {
                if (allItemKeys.includes(prevSelectedItems[i])) {
                    next.push(prevSelectedItems[i]);
                }
            }

            return next;
        });
    }, [allItemKeys]);

    const isItemSelected = useCallback((itemKey) => selectedItems.includes(itemKey), [selectedItems]);

    const isAllSelected = useMemo(() => allItemKeys.length && selectedItems.length === allItemKeys.length, [selectedItems, allItemKeys]);

    const isPartialSelected = useMemo(() => Boolean(!isAllSelected && selectedItems.length), [isAllSelected, selectedItems]);

    const toggleAllItems = useCallback(() => {
        if (singleSelection) {
            return;
        }

        if (selectedItems.length) {
            setSelectedItems([]);
        } else {
            setSelectedItems([...allItemKeys]);
        }
    }, [allItemKeys, selectedItems, singleSelection]);

    const toggleItem = useCallback((itemKey) => {
        setSelectedItems((selectedItems) => {
            if (selectedItems.includes(itemKey)) {
                return selectedItems.filter((item) => item !== itemKey);
            }

            if (singleSelection) {
                return [itemKey];
            }

            return selectedItems.concat(itemKey);
        });
    }, []);

    const clearSelection = useCallback(() => setSelectedItems([]), []);

    return {
        isItemSelected,
        isAllSelected,
        isPartialSelected,
        toggleAllItems,
        toggleItem,
        selectedItems,
        clearSelection
    };
}