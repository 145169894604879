import {LogLevel} from '@injex/core';
import {EnvPlugin} from '@injex/env-plugin';
import {ReactPlugin} from '@injex/react-plugin';
import {Injex} from '@injex/webpack';
import dayjs from 'dayjs';
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import {configure} from 'mobx';
import IEnv from './common/interfaces/IEnv';
import './modules/ui/styles/main.scss';
import reportWebVitals from './reportWebVitals';

dayjs.extend(timezonePlugin);
dayjs.extend(utcPlugin);
dayjs.extend(customParseFormatPlugin);

dayjs.Ls.en.weekStart = 1;

configure({
    enforceActions: 'never',
});

const container = Injex.create({
    resolveContext: () => require.context(__dirname, true, /\.mdl\.tsx?$/),
    plugins: [
        new EnvPlugin<IEnv>({
            defaults: {
                recaptchaSiteKey: '6LdpvEAUAAAAAADPotdQeuDkMG5xp9LMrARwqRQ9',
                publishersAPI: 'https://publishers-api-staging.vidazoo.com',
                // publishersAPI: process.env.REACT_APP_MODE === 'staging'
                //     ? 'https://publishers-api-staging.vidazoo.com'
                //     : 'https://publishers-api.vidazoo.com',
                contentAPI: 'https://vidazoo-website-api.herokuapp.com'
            }
        }),
        new ReactPlugin({
            rootElementOrSelector: '#root',
        })
    ]
});

container.logger
    .setNamespace('Publishers')
    .setLogLevel(LogLevel.Info);

if (process.env.NODE_ENV === 'development') {
    container.logger.setLogLevel(LogLevel.Debug);
    (window as any).$injex = container;
}

container.bootstrap();

reportWebVitals(console.log);