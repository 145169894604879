import {define, inject, singleton} from '@injex/core';
import {observable} from 'mobx';
import DataQuery from '../../../common/models/DataQuery';
import {BlogAPI} from '../api/blog.mdl';
import IArticleEntry from '../interfaces/IArticleEntry';

@define()
@singleton()
export class BlogManager {
    @inject() private blogAPI: BlogAPI;

    public articles: DataQuery<IArticleEntry[]>;

    constructor() {
        this.articles = new DataQuery<IArticleEntry[]>(this._fetchArticles.bind(this), {defaultValue: [], ttl: -1});
    }

    private async _fetchArticles() {
        const response = await this.blogAPI.getArticles();
        return observable.array(response.results, {deep: false});
    }
}