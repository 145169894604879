export enum SiteListFilter {
    ALL = 'all',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    PENDING = 'pending'
}

export enum AdsTxtStatus {
    // In case all records implemented
    RECORDS_IMPLEMENTED = 'Records Implemented',

    // In case all records missing
    RECORDS_NOT_IMPLEMENTED = 'Records not Implemented',

    // In case records under review
    RECORDS_UNDER_REVIEW = 'Records Under Review',

    // In case one or more records missing
    MISSING_RECORDS = 'Missing Records',

    // In case it was not scanned yet
    PENDING_CHECK = 'Pending Check',

    // In cases scanned but Error
    CHECK_ERROR = 'Check Error',
}

export enum SitesAction {
    Delete = 'delete',
    ScanAdsText = 'scan_ads_text',
    ScanOwnedBy = 'scan_owned_by',
    ApproveMarketplayer = 'approve_marketplace',
    RejectMarketplace = 'reject_marketplace'
}