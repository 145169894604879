import clsx from 'clsx';
import {memo, useEffect, useState} from 'react';
import theme from './theme.module.scss';

export interface IStickyScrollbarProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
    target: any;
    extraData: any;
}

export interface IStickyScrollbarState {

}

function StickyScrollbar({className, target, extraData, ...others}: IStickyScrollbarProps): JSX.Element {
    const [calculatedWidth, setCalculatedWidth] = useState(0);
    const [showScrollbar, setShowScrollbar] = useState(false);
    const [isOnBottom, setOnBottom] = useState(false);

    const onScroll = (e) => {
        target.current.scrollTo({left: e.target.scrollLeft});
    };

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setOnBottom((document.body.clientHeight - 15 < window.innerHeight + window.scrollY));
        });
    }, []);

    useEffect(() => {
        if (extraData && extraData.columnsWidth) {
            const sumColumnsWidth: number = Object.values<number>(extraData.columnsWidth).reduce((a, b) => a + b);
            setCalculatedWidth(sumColumnsWidth);
        }
    }, [extraData?.columnsWidth]);

    useEffect(() => {
        setShowScrollbar(target && target.current && calculatedWidth > target.current.offsetWidth);
    }, [target.current, calculatedWidth]);

    if (!showScrollbar) {
        return null;
    }

    return (
        <div onScroll={onScroll} {...others} className={clsx(theme.stickyScrollbar, className)}
            style={{width: target.current.offsetWidth, bottom: isOnBottom ? 41 : 0}}>
            <div className={clsx(theme.scrollbarTrack, className)} style={{width: calculatedWidth}} />
        </div>
    );
}

export default memo(StickyScrollbar);
