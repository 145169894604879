import {define, singleton} from '@injex/core';
import EntityRequestAdapter from '../../../stdlib/EntityRequestAdapter';
import IViewAsUser from '../interfaces/IViewAsUser';

@define('viewasAPI')
@singleton()
export class ViewAsAPI extends EntityRequestAdapter {
    protected basePathName: string = 'view_as';

    public async getViewAsUsers(): Promise<IViewAsUser[]> {
        const response = await this.requestWithContext<IViewAsUser[]>({
            method: 'get',
            url: this.makeAPIPath('/')
        });

        return response.data;
    }
}