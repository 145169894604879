import {define, inject} from '@injex/core';
import {computed, makeObservable} from 'mobx';
import DataQuery from '../../../../common/models/DataQuery';
import {ColumnsManager} from '../../../ui/components/ui-virtualized';
import {SiteListAPI} from '../../api/siteList.mdl';
import ISite, {AdsTxtRecord, ISiteUser} from '../../interfaces/ISite';
import {AdsTxtStatus} from '../../site-list/common/enums';
import {SiteIssues} from '../common/enums';

@define()
export class SiteModel extends ColumnsManager<AdsTxtRecord> {
    @inject() private siteListAPI: SiteListAPI;

    public site: DataQuery<ISite, [id: string]>;

    constructor() {
        super({
            persistKey: 'site_ads_rec_columns'
        });

        this.setColumns([
            {title: 'Domain', key: 'domain', defaultWidth: 300, options: {search: {searchKey: 'domain'}, sort: {sortKey: 'domain'}, freeze: true}},
            {title: 'Publisher Account ID', key: 'publisherAccountId', defaultWidth: 300, options: {search: {searchKey: 'domain'}, sort: {sortKey: 'domain'}, freeze: true}},
            {title: 'Account Type', key: 'accountType', options: {sort: {sortKey: 'accountType'}, filter: {filterKey: 'accountType', options: ['RESELLER', 'DIRECT']}}},
            {title: 'Certificate Authority ID', key: 'certificateAuthorityID', defaultWidth: 240, options: {sort: {sortKey: 'certificateAuthorityID'}}},
            {title: 'Implemented', key: 'implemented', options: {sort: {sortKey: 'implemented'}, filter: {filterKey: 'implemented', options: [{value: true, label: 'Implemented'}, {value: false, label: 'Not Implemented'}]}}}
        ]);

        makeObservable(this);

        this.site = new DataQuery(this._fetchSite.bind(this));
    }

    protected get data(): AdsTxtRecord[] {
        return this.site.data?.adsTxtRecordsStatus ?? [];
    }

    private _fetchSite(id: string): Promise<ISite> {
        return this.siteListAPI.getById(id, {});
    }

    public async updateSite(key: keyof ISite, value) {
        this.site.data[key] = value as never;
    }

    @computed public get issues(): SiteIssues[] {
        if (!this.site.data || this.site.isLoading)
            return [];

        const issues = [];

        const companyDomain = (this.site.data.user as ISiteUser).companyDomain;

        if (companyDomain && this.site.data.ownedBy) {
            issues.push(
                companyDomain === this.site.data.ownedBy ? SiteIssues.OwnerValid : SiteIssues.OwnerMismatch
            );
        }

        if (this.site.data.adsTxtStatus === AdsTxtStatus.MISSING_RECORDS) {
            issues.push(
                SiteIssues.MissingRecords
            );
        }

        return issues;
    }

    @computed public get missingRecordsCount(): number {
        return this.site.data?.adsTxtStatus === AdsTxtStatus.MISSING_RECORDS ? this.site.data?.adsTxtRecordsStatus.filter((record) => !record.implemented).length : 0;
    }

    @computed public get missingRecords(): string {
        return this.site.data?.adsTxtRecordsStatus
            .filter((record) => !record.implemented)
            .map((r) => `${r.domain}, ${r.publisherAccountId}, ${r.accountType}, ${r.certificateAuthorityID}`)
            .join('\n');
    }
}