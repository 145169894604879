export function isDefined(predicate: any): boolean {
    return typeof predicate !== 'undefined';
}

export function isFunction(predicate: any): boolean {
    return isDefined(predicate) && typeof predicate === 'function';
}

export function isString(predicate: any): boolean {
    return typeof predicate === 'string';
}

export function isNotEmptyString(predicate: any): boolean {
    return isString(predicate) && predicate !== '';
}

export function isNumber(predicate: any): boolean {
    return !isNaN(predicate) && typeof predicate === 'number';
}

export function isBoolean(predicate: any): boolean {
    return isDefined(predicate) && typeof predicate === 'boolean';
}

// eslint-disable-next-line
const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

export const isIOSSafari: boolean = isSafari && iOS;
export const isAndroidChrome: boolean = /Android/i.test(navigator.userAgent) && /Chrome/i.test(navigator.userAgent);

export function all(...cases: boolean[]): boolean {
    for (let i = 0, len = cases.length; i < len; i++) {
        if (!cases[i]) {
            return false;
        }
    }

    return true;
}

export function any(...cases: any[]): boolean {
    for (let i = 0, len = cases.length; i < len; i++) {
        if (Boolean(cases[i])) {
            return true;
        }
    }

    return false;
}

export function every(...cases: boolean[]): boolean {
    for (let i = 0, len = cases.length; i < len; i++) {
        if (!cases[i]) {
            return false;
        }
    }

    return true;
}