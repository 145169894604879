import {isDefined} from './assert';

const ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

export function guid(len = 8): string {
    let g = '';
    for (let i = 0; i < len; i++) {
        g += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
    }

    return g;
}

export function getTimezonOffsetFromUTC(utc: string): number {
    if (utc.endsWith('00:00')) {
        return 0;
    }

    return Number((utc.indexOf('-') > -1 ? utc.replace('-', '+') : utc.replace('+', '-')).replace(/[:0]/g, ''));
}

export function convertUTCOffsetToMomentTimezone(utc: string) {
    if (utc.endsWith('00:00')) {
        utc = '+0';
    } else {
        utc = (utc.indexOf('-') > -1 ? utc.replace('-', '+') : utc.replace('+', '-')).replace(/[:0]/g, '');
    }

    return `Etc/GMT${utc}`;
}

export function take<T>(arr: T[], count: number): T[] {
    return arr.slice(0, count);
}

export function formatNumber(num: number, fixed?: number) {
    var value = (num || 0).toString();
    var options: Intl.NumberFormatOptions = {};
    if (!isNaN(fixed)) {
        options.maximumFractionDigits = fixed;
    }
    return parseFloat(value).toLocaleString(undefined, options);
}

export function remove<T>(arr: T[], shouldRemove: (item: T) => boolean): T[] {
    const removedItems: T[] = [];

    arr.filter((item) => {
        if (shouldRemove(item)) {
            removedItems.push(item);
            return false;
        }

        return true;
    });

    return removedItems;
}

export function compact(arr: any[]): any[] {
    if (!arr) {
        return [];
    }

    return arr.filter((item) => isDefined(item) && item !== null);
}

export function uniqueBy(arr: any[], key: string): any[] {
    const unique = [];
    const index: any = {};
    for (let i = 0, len = arr.length; i < len; i++) {
        if (!index[arr[i][key]]) {
            unique.push(arr[i]);
            index[arr[i][key]] = true;
        }
    }

    return unique;
}

export function isInRange(val: number, [min, max]): boolean {
    return val >= min && val <= max;
}

export function get<T = any>(predicate: any, path: string): T {
    let value = predicate;
    const parts = path.split('.');

    while (parts.length) {
        const part = parts.shift();
        value = value[part];
    }

    return value;
}

export function download(content: any, fileName: string) {
    const blob = new Blob([content]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}