import {define, inject, singleton} from '@injex/core';
import {computed, makeObservable, observable} from 'mobx';
import DataQuery from '../../../common/models/DataQuery';
import {podcastAPI} from '../api/podcast.mdl';
import IPodcastEpisode from '../interfaces/IPodcastEpisode';

@define()
@singleton()
export class PodcastManager {
    public readonly playerHeight: number = 93;

    @inject() private podcastAPI: podcastAPI;

    public episodes: DataQuery<IPodcastEpisode[]>;

    @observable.ref public playingEpisode: IPodcastEpisode;

    constructor() {
        makeObservable(this);

        this.episodes = new DataQuery<IPodcastEpisode[]>(this._fetchEpisodes.bind(this), {defaultValue: [], ttl: -1});
    }

    @computed public get isPlaying(): boolean {
        return Boolean(this.playingEpisode);
    }

    private async _fetchEpisodes() {
        const response = await this.podcastAPI.getEpispdes();
        return observable.array(response.results, {deep: false});
    }

    public playEpisode(episode: IPodcastEpisode) {
        if (episode.id === this.playingEpisode?.id) {
            return this.stopPlayingEpisode();
        }

        this.playingEpisode = episode;
    }

    public stopPlayingEpisode() {
        this.playingEpisode = null;
    }
}