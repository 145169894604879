import {define, inject} from '@injex/core';
import DataQuery from '../../../../common/models/DataQuery';
import IAccountIcon from '../../interfaces/IAccountIcon';
import {AccountManager} from '../../../account/managers/accountManager.mdl';
import {AccountManagementAPI} from '../../api/accountManagement.mdl';
import {IAccountManagementDetails} from '../../interfaces/IAccountManagementDetails';
import IAccountUser from '../../interfaces/IAccountUser';
import {SessionManager} from '../../../session/managers/sessionManager.mdl';
import {makeObservable, observable} from 'mobx';
import IAccountInvitation from '../../interfaces/IAccountInvitation';
import {INITIAL_ACCOUNT_USER_ROLES, PUBLISHER_ORG} from '../../../../common/constants';

@define()
export class AccountManagementModel {

    @inject() private accountManagementAPI: AccountManagementAPI;
    @inject() private accountManager: AccountManager;
    @inject() private sessionManager: SessionManager;

    public accountDetails: DataQuery<IAccountManagementDetails>;
    public accountUsers: DataQuery<IAccountUser[]>;
    public accountInvitations: DataQuery<IAccountInvitation[]>;
    public accountIcon: DataQuery<IAccountIcon>;

    @observable public removingUser: boolean;
    @observable public removingInvite: boolean;
    @observable public sendingInvite: boolean;

    constructor() {
        makeObservable(this);
        this.accountDetails = new DataQuery(this._fetchAccountDetails.bind(this));
        this.accountUsers = new DataQuery(this._fetchAccountUsers.bind(this), {defaultValue: []});
        this.accountInvitations = new DataQuery(this._fetchAccountInvitations.bind(this), {defaultValue: []});
        this.accountIcon = new DataQuery(this._fetchAccountIcon.bind(this));
    }

    public fetch() {
        this.accountDetails.fetch();
        this.accountUsers.fetch();
        this.accountInvitations.fetch();
        this.accountIcon.fetch();
    }

    private async _fetchAccountDetails(): Promise<IAccountManagementDetails> {
        return this.accountManagementAPI.getAccountDetails(this.accountManager.selectedAccountId);
    }

    private async _fetchAccountUsers(): Promise<IAccountUser[]> {
        const data = await this.accountManagementAPI.getAccountUsers(this.accountManager.selectedAccountId);
        return data.results;
    }

    private async _fetchAccountInvitations(): Promise<IAccountInvitation[]> {
        // return [{_id: '1', email: 'udi@vidazoo.com', created: 1668532360561}, {_id: '2', email: 'roman@vidazoo.com', created: 1668532360561}]; // mock for testing
        return this.accountManagementAPI.getAccountInvitations(this.accountManager.selectedAccountId);
    }

    private async _fetchAccountIcon(): Promise<IAccountIcon> {
        return this.accountManagementAPI.getAccountIcon(this.accountManager.selectedAccountId);
    }

    public async removeUserFromAccount(userId: string) {
        const {firstName, lastName, email, _id} = this.sessionManager.user;

        try {
            this.removingUser = true;
            await this.accountManagementAPI.removeUserFromAccount({
                userId,
                scope: 'publishers',
                accountId: this.accountDetails.data._id,
                user: {firstName, lastName, email, _id}
            });

            this.accountUsers.data = this.accountUsers.data.filter((user) => user._id !== userId);
        } catch (e) {

        } finally {
            this.removingUser = false;
        }
    }

    public async removeInvitation(inviteId: string) {
        const {firstName, lastName, email, _id} = this.sessionManager.user;

        try {
            this.removingInvite = true;
            await this.accountManagementAPI.removeInvitationFromAccount({
                inviteId,
                scope: 'publishers',
                accountId: this.accountDetails.data._id,
                user: {firstName, lastName, email, _id}
            });

            this.accountInvitations.data = this.accountInvitations.data.filter((invite) => invite._id !== inviteId);
        } catch (e) {

        } finally {
            this.removingInvite = false;
        }
    }

    public async sendInvitation(sendToMail: string) {
        const {firstName, lastName, email, _id} = this.sessionManager.user;

        try {
            this.sendingInvite = true;

            const invitation = await this.accountManagementAPI.sendAccountInvitation({
                email: sendToMail,
                accountId: this.accountManager.selectedAccountId,
                roles: INITIAL_ACCOUNT_USER_ROLES,
                organization: PUBLISHER_ORG,
                callbackUrl: window.location.origin,
                scope: 'publishers',
                user: {firstName, lastName, email, _id}
            });

            this.accountInvitations.data.push(invitation);
        } catch (e) {

        } finally {
            this.sendingInvite = false;
        }

    }
}