import clsx from 'clsx';
import {createRef, PureComponent} from 'react';
import theme from './theme.module.scss';

enum KeyCodes {
    Enter = 13,
    Escape = 27
}

export interface IEditableProps extends React.HtmlHTMLAttributes<HTMLDivElement> {

    item?: any;

    value: any;

    truncate?: boolean;

    inputType?: string;

    inputClassName?: string;

    onEdit: (value: any, item?: any) => void;
}

export interface IEditableState {

    editMode: boolean;

}

export default class Editable extends PureComponent<IEditableProps, IEditableState> {

    public static defaultProps: Partial<IEditableProps> = {

        inputType: 'text',

        truncate: true

    };

    private inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: IEditableProps, context) {
        super(props, context);

        this.state = {editMode: false};
        this.inputRef = createRef<HTMLInputElement>();
    }

    private toggleEditMode(cb?) {
        this.setState({editMode: !this.state.editMode}, cb);
    }

    private onContainerClick = (e) => {
        this.props.onClick && this.props.onClick(e);

        if (!this.state.editMode) {

            const {anchorOffset} = window.getSelection();

            this.toggleEditMode(() => {
                this.inputRef.current.focus();
                this.inputRef.current.setSelectionRange(anchorOffset, anchorOffset);
            });
        }
    };

    private handleFinishEdit = () => {

        const {value, item, onEdit} = this.props;
        const newValue = this.inputRef.current.value;

        this.toggleEditMode();

        if (newValue === value) {
            return;
        }

        onEdit(newValue, item);
    };

    private handleCancelEdit() {

        this.toggleEditMode();
    }

    private onInputKeyDown = (e: React.KeyboardEvent) => {
        switch (e.which) {

            case KeyCodes.Enter:
                this.handleFinishEdit();
                break;

            case KeyCodes.Escape:
                this.handleCancelEdit();
                break;
        }
    };

    public render(): JSX.Element {

        const {className, truncate, inputClassName, value, inputType, onEdit, item, ...others} = this.props;
        const {editMode} = this.state;

        return (
            <div
                {...others}
                onClick={this.onContainerClick}
                className={clsx(theme.editable, className, {
                    [theme.truncate]: truncate
                })}
            >
                {editMode ? (
                    <input
                        data-editable-input
                        ref={this.inputRef}
                        type={inputType}
                        defaultValue={value}
                        onKeyDown={this.onInputKeyDown}
                        onBlur={this.handleFinishEdit}
                        className={inputClassName}
                    />
                ) : value}
            </div>
        );
    }
}
