import clsx from 'clsx';
import {memo, useCallback, useMemo, useRef, useState} from 'react';
import ResizeContext from './ResizeContext';
import StickyScrollbar from './StickyScrollbar';
import TableContext from './TableContext';
import theme from './theme.module.scss';
import {clampValue, ColumnWidthGetter} from './utils';
import Virtualized, {IVirtualizedProps} from './Virtualized';

const debug = process.env.NODE_ENV === 'development';

function Debugger({width}) {
    return debug ? <span style={{fontSize: 11, padding: 5, background: 'rgba(255, 255, 0, .5)'}}>{width}px</span> : null;
}

// function defaultRenderResizer(left: number, width: number) {
//     return (
//         <div className={theme.resizer} style={{left, width}}>
//             {debug ? <span style={{fontSize: 11, padding: 5, background: 'rgba(255, 255, 0, .5)'}}>{width}px</span> : null}
//         </div>
//     );
// }

function lineResizer(left: number, width: number) {
    return (
        <div className={theme.lineResizer} style={{left, width: width - 1}}>
            <Debugger width={width} />
        </div>
    );
}

export interface ITableProps extends IVirtualizedProps {
    className?: string;
    enableColumnResizing?: boolean;
    minColumnWidth?: number;
    maxColumnWidth?: number;
    freezeColumnKey?: string;
    getColumnWidth?: ColumnWidthGetter;
    disableResizer?: boolean;
    onColumnResize?(width: number, colKey?: string): void;
    renderResizer?(left: number, width: number): JSX.Element;
    useStickyScrollbar?: boolean;
}

export interface ITableState {

}

function Table({
    onColumnResize,
    rowHeight,
    itemsCount,
    renderRow,
    viewportHeight,
    viewportWidth,
    viewportRows,
    overscanItems,
    extraData,
    calculateInitialWidth,
    renderHeader,
    renderFooter,
    onScroll,
    onBeforeScrollEnd,
    className,
    enableColumnResizing,
    minColumnWidth = 100,
    maxColumnWidth = Infinity,
    freezeColumnKey,
    renderResizer = lineResizer,
    disableResizer = false,
    useStickyScrollbar
}: ITableProps): JSX.Element {

    const [resize, setResize] = useState({
        width: 0,
        left: 0,
        enabled: false
    });
    const container = useRef<HTMLDivElement>();
    const viewport = useRef<HTMLDivElement>();

    const setResizeHandler = useCallback((enabled: boolean, {left, width, colKey}) => {
        window.requestAnimationFrame(() => {
            const offsetLeft = container.current.getBoundingClientRect().left;
            const nextWidth = clampValue(width, [minColumnWidth, maxColumnWidth]);
            setResize({enabled, left: left - offsetLeft, width: nextWidth});
            onColumnResize && onColumnResize(nextWidth, colKey);
        });
    }, [minColumnWidth, maxColumnWidth, onColumnResize]);

    const resizeContextValue = useMemo(() => ({
        setResizeHandler
    }), [setResizeHandler]);

    const tableContextValue = useMemo(() => ({
        freezeColumnKey,
        minColumnWidth,
        maxColumnWidth
    }), [
        freezeColumnKey,
        minColumnWidth,
        maxColumnWidth
    ]);

    return (
        <TableContext.Provider value={tableContextValue}>
            <div ref={container} className={clsx(theme.table, className)} style={{
                pointerEvents: resize.enabled ? 'none' : 'all'
            }}>
                {enableColumnResizing && resize.enabled && !disableResizer && renderResizer(resize.left, resize.width)}
                <ResizeContext.Provider value={resizeContextValue}>
                    <Virtualized
                        {...{
                            ref: viewport,
                            rowHeight,
                            itemsCount,
                            renderRow,
                            viewportHeight,
                            viewportWidth,
                            viewportRows,
                            overscanItems,
                            extraData,
                            calculateInitialWidth,
                            renderHeader,
                            renderFooter,
                            onScroll,
                            onBeforeScrollEnd
                        }}
                    />
                </ResizeContext.Provider>
            </div>
            {(useStickyScrollbar) && (
                <StickyScrollbar extraData={extraData} target={viewport} />
            )}
        </TableContext.Provider>
    );
}

export default memo(Table);
