import {alias, define, init, inject, singleton} from '@injex/core';
import {SessionManager} from '../../session/managers/sessionManager.mdl';
import * as socket from 'socket.io-client';
import IEnv from '../../../common/interfaces/IEnv';
import {guid} from '../../../stdlib/utils';
import {Injex} from '@injex/webpack';
import IDisposable from '../../../common/interfaces/IDisposable';

@define()
@singleton()
@alias('Disposable')
export class SocketService implements IDisposable {
    @inject() private sessionManager: SessionManager;
    @inject() private env: IEnv;
    @inject() private $injex: Injex;

    private _socket: socket.Socket;
    private _requests: { [index: string]: { resolve: (...args: any[]) => void; reject: (error: Error) => void }; };

    constructor() {
        this._requests = {};
    }

    @init()
    public initialize() {
        this.sessionManager.hooks.loginSuccess.tapAsync(this._onLogin, null, this);
    }

    private _onLogin() {
        return new Promise((resolve, reject) => {
            this.$injex.logger.info('Connecting to socket...');
            const {accessToken} = this.sessionManager.getRequestContext();

            this._socket = socket.io(this.env.publishersAPI, {
                secure: process.env.NODE_ENV === 'production',
                transports: ['websocket'],
                reconnectionAttempts: 100,
                auth: {
                    token: accessToken
                }
            });

            this._socket.on('connect', this._onConnect.bind(this, resolve));
            this._socket.on('connect_error', this._onConnectError.bind(this, reject));
            this._socket.on('connect_timeout', this._onConnectTimeout.bind(this, reject));
            this._socket.on('error', this._onError.bind(this));
            this._socket.on('disconnect', this._onDisconnect.bind(this));
        });
    }

    private _onConnect(resolve) {
        this.$injex.logger.info('Socket connected.');
        resolve();
    }

    private _onDisconnect() {
        this.$injex.logger.warn('Socket disconnected.');
    }

    private _onConnectError(reject) {
        this.$injex.logger.error('Socket connection error.');
        reject();
    }

    private _onConnectTimeout(reject) {
        this.$injex.logger.error('Socket connection timed out.');
        reject();
    }

    private _onError(e) {
        this.$injex.logger.error('Socket error.', e);
    }

    public request<T>(command: string, data: any, expire: number = 5 * 60 * 1000): Promise<T> {
        return new Promise((resolve, reject) => {
            if (!this._socket.connected) {
                return reject({message: 'service_unavailable', code: 503});
            }

            const id = guid();

            this._requests[id] = {resolve, reject};

            const timeout = window.setTimeout(() => {
                delete this._requests[id];
                reject({message: 'request_timeout', code: 408});
            }, expire);

            this._socket.emit(command, data, (reply: { success: boolean, data: T }) => {
                delete this._requests[id];
                window.clearTimeout(timeout);
                reply.success ? resolve(reply.data) : reject(reply);
            });
        });
    }

    public dispose(): void {
        if (this._socket?.connected) {
            this._socket.disconnect();
        }
    }
}