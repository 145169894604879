import * as React from 'react';
import {isBoolean} from '../../stdlib/assert';

export default function useToggle(initialValue = false): [state: boolean, toggle: (...args: any[]) => void] {
    const [state, setState] = React.useState<boolean>(initialValue);
    const toggle = React.useCallback((force?: boolean) => {
        setState((current) => isBoolean(force) ? force : !current);
    }, []);

    return [state, toggle];
}