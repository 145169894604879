import {alias, define, inject, singleton} from '@injex/core';
import IDisposable from '../../../common/interfaces/IDisposable';
import {IAccountActivityEntry} from '../../account/interfaces/IAccountMetadata';
import {AccountManager} from '../../account/managers/accountManager.mdl';

@define()
@singleton()
@alias('Disposable')
export class ActivitiesManager implements IDisposable {
    @inject() private accountManager: AccountManager;

    public get accountActivities(): IAccountActivityEntry[] {
        return this.accountManager.selectedAccount?.activities ?? [];
    }

    public getActivityEntry(activityId: string): IAccountActivityEntry {
        return this.accountActivities.find((activity) => activity._id === activityId);
    }

    public dispose(): void {
    }
}