import {makeObservable, observable} from 'mobx';

export type DataQueryFetcher<T, Args extends Array<any> = any[]> = (...args: Args) => Promise<T>;

export type DataQueryOptions<T> = {
    /**
     * Time in milliseconds for invalidation.
     * Set to -1 to NEVER
     *
     * @type Number
     * @default 60000
     */
    ttl?: number;

    /**
     * Default value to be used if data is pristine.
     *
     * @type T
     */
    defaultValue?: T;

    /**
     * Sets the data observable object type from mobx
     * For example: `observable` or `observable.ref`
     *
     * @type Object
     * @default observable
     */
    dataObservType?: any;

    /**
     * For debugging, throttle the reponse from `fetch`
     *
     * @type Number
     */
    throttle?: number;
};

function defaults<T>(options: DataQueryOptions<T> = {}): DataQueryOptions<T> {
    return {
        ttl: 1000 * 60,
        dataObservType: observable,
        ...options
    };
}

export default class DataQuery<T, Args extends Array<any> = any[]> {
    public isLoading: boolean;
    public didLoad: boolean;
    public error: Error;
    public data: T;

    private _options: DataQueryOptions<T>;
    private _fetcher: DataQueryFetcher<T, Args>;
    private _lastFetch: number;
    private _isPristine: boolean;

    constructor(fetcher: DataQueryFetcher<T, Args>, options?: DataQueryOptions<T>) {
        this._options = defaults(options);

        makeObservable(this, {
            data: this._options.dataObservType,
            isLoading: observable,
            didLoad: observable,
            error: observable
        });
        this.data = this._options.defaultValue;
        this._fetcher = fetcher;
        this._isPristine = true;
        this.isLoading = true;
        this.didLoad = false;
        this.error = null;
        this.invalidate();
    }

    public reset() {
        this.data = this._options.defaultValue;
        this._lastFetch = 0;
        this.error = null;
        this.didLoad = false;
    }

    public invalidate(): DataQuery<T, Args> {
        this._lastFetch = 0;
        return this;
    }

    public async fetch(...args: Args) {
        if (this.isLoading && !this._isPristine) { return; }

        this._isPristine = false;

        try {
            if (!this._lastFetch || (this._lastFetch + this._options.ttl < Date.now() && !(this.didLoad && this._options.ttl === -1))) {
                this.isLoading = true;
                this.error = null;
                if (this._options.throttle) {
                    await new Promise((resolve) => setTimeout(resolve, this._options.throttle));
                }
                this.data = await this._fetcher(...args);
                this._lastFetch = Date.now();
                this.didLoad = true;
            }
        } catch (e) {
            this.data = null;
            this.didLoad = false;
            this.error = e;
        } finally {
            this.isLoading = false;
        }
    }
}