const NUMBER = 'number';

export type ColumnWidth = string | number;

export type ColumnWidthGetter = (cellIndex: number, columnsCount: number) => ColumnWidth;

export type TableColumn<T> = {
    title: string;
    key: string;
    defaultWidth?: number;
    getValue?: (item: T) => string | JSX.Element;
    options?: {
        search?: { searchKey: string | ((item: T, query: string) => boolean); };
        sort?: { sortKey: string | ((item: T) => number | string); };
        filter?: { filterKey: string; options: Array<string | { value: any; label: string; check?: (item: T) => boolean; }>; };
        freeze?: boolean;
    }
};

export interface IAnimationTimeoutId {
    id: number;
}

export function debounce(fn, wait) {
    let timer;
    // tslint:disable-next-line
    return function () {
        const args = Array.prototype.slice.call(arguments);
        clearTimeout(timer);
        // @ts-ignore
        timer = setTimeout(fn, wait, ...args);
    };
}

export function clampValue(value, [min, max]) {
    return Math.min(Math.max(min, value), max);
}

export function isDefined(predicate: any): boolean {
    return typeof predicate !== 'undefined';
}

export function requestAnimationTimeout(callback, delay: number): IAnimationTimeoutId {
    let start;

    Promise.resolve().then(() => {
        start = Date.now();
    });

    const timeout = () => {
        if (Date.now() - start >= delay) {
            callback.call();
        } else {
            frame.id = window.requestAnimationFrame(timeout);
        }
    };

    const frame: IAnimationTimeoutId = {
        id: window.requestAnimationFrame(timeout),
    };

    return frame;
}

export function cancelAnimationTimeout(frame: IAnimationTimeoutId) {
    window.cancelAnimationFrame(frame.id);
}

export function createAnimationFrameScheduler() {
    let queuedCallback;
    return (callback) => {
        if (!queuedCallback) {
            window.requestAnimationFrame(() => {
                queuedCallback();
                queuedCallback = null;
            });
        }

        queuedCallback = callback;
    };
}

export function isNumber(predicate: any): boolean {
    return typeof predicate === NUMBER && !isNaN(predicate);
}

export function px(value): string {
    return isNumber(value) ? `${value}px` : value;
}

export function memoize(hashFn: (args: any[]) => string = (args) => args.map((arg) => String(arg)).join('_')) {

    return function (target, propertyKey: string, descriptor: PropertyDescriptor) {

        const method = descriptor.value;

        descriptor.value = function (...args) {

            if (!descriptor.value.__cache) {
                descriptor.value.__cache = {};
            }

            const hash = hashFn(args);

            if (!descriptor.value.__cache[hash]) {
                descriptor.value.__cache[hash] = method.apply(this, args);
            }

            return descriptor.value.__cache[hash];
        };

        return descriptor;
    };
}
