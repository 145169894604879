import {define, init, singleton} from '@injex/core';

export enum Theme {
    Dark = 'dark',
    Light = 'light',
    Auto = 'auto'
}

@define('themeManager')
@singleton()
export class ThemeManager {

    private _theme: Theme;

    @init()
    protected initialize() {
        if (localStorage.theme) {
            this.setTheme(localStorage.theme);
        } else {
            this.setTheme();
        }

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
            if (this._theme === Theme.Auto) {
                this.setTheme();
            }
        });
    }

    public get theme(): Theme {
        return this._theme;
    }

    public setTheme(theme: Theme = Theme.Auto) {
        if (theme !== Theme.Auto) {
            window.localStorage.setItem('theme', theme);
            document.documentElement.classList.toggle('dark', theme === Theme.Dark);
            this._theme = theme;
        } else {
            window.localStorage.removeItem('theme');
            document.documentElement.classList.toggle('dark', window.matchMedia('(prefers-color-scheme: dark)').matches);
            this._theme = Theme.Auto;
        }
    }
}