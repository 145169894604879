import {define, singleton} from '@injex/core';
import EntityRequestAdapter from '../../../stdlib/EntityRequestAdapter';
import ISite from '../interfaces/ISite';

@define()
@singleton()
export class SiteListAPI extends EntityRequestAdapter<ISite> {
    protected basePathName: string = 'site_list';

    public async getAdsTxt(): Promise<string> {
        const response = await this.requestWithContext<string>({
            url: this.makeAPIPath('/adstxt/'),
            method: 'get'
        });

        return response.data;
    }
}