import {define, singleton} from '@injex/core';
import EntityRequestAdapter, {ListResults} from '../../../stdlib/EntityRequestAdapter';
import IAccountIcon from '../interfaces/IAccountIcon';
import IAccountInvitation from '../interfaces/IAccountInvitation';
import {IAccountManagementDetails} from '../interfaces/IAccountManagementDetails';
import IAccountUser from '../interfaces/IAccountUser';

export type RequestUser = {
    _id: string;
        email: string;
        firstName: string;
        lastName: string;
};

export type RemoveAccountUserRequestBodyParams = {
    user: RequestUser;
    accountId: string;
    scope: string;
    userId: string;
};

export type RemoveAccountInvitationRequestBodyParams = {
    user: RequestUser;
    accountId: string;
    scope: string;
    inviteId: string;
};

export type SendAccountInvitationRequestBodyParams = {
    email: string;
    accountId: string;
    roles: string[];
    organization: string;
    callbackUrl: string;
    scope: string;
    user: RequestUser;
};

@define()
@singleton()
export class AccountManagementAPI extends EntityRequestAdapter {
    protected basePathName: string = 'account_management';

    public async getAccountDetails(accountId: string): Promise<IAccountManagementDetails> {
        const response = await this.requestWithContext<IAccountManagementDetails>({
            method: 'get',
            url: this.makeAPIPath(`/account/${accountId}`)
        });

        return response.data;
    }

    public async getAccountUsers(accountId: string): Promise<Omit<ListResults<IAccountUser>, 'count'>> {
        const response = await this.requestWithContext<Omit<ListResults<IAccountUser>, 'count'>>({
            method: 'get',
            url: this.makeAPIPath(`/accounts_by_id/${accountId}`)
        });

        return response.data;
    }

    public async getAccountIcon(accountId: string): Promise<IAccountIcon> {
        const response = await this.requestWithContext<IAccountIcon>({
            method: 'get',
            url: `/api/publishers/utils/${accountId}/favicon`
        });

        return response.data;
    }

    public async getAccountInvitations(accountId: string): Promise<IAccountInvitation[]> {
        const response = await this.requestWithContext<IAccountInvitation[]>({
            method: 'get',
            url: this.makeAPIPath(`/invitations/${accountId}`)
        });

        return response.data;
    }

    public async removeUserFromAccount(data: RemoveAccountUserRequestBodyParams): Promise<void> {
        const response = await this.requestWithContext<null>({
            method: 'post',
            url: this.makeAPIPath('/remove_account_user'),
            data
        });

        return response.data;
    }

    public async removeInvitationFromAccount(data: RemoveAccountInvitationRequestBodyParams): Promise<void> {
        const response = await this.requestWithContext<null>({
            method: 'post',
            url: this.makeAPIPath('/remove_account_invitation'),
            data
        });

        return response.data;
    }

    public async sendAccountInvitation(data: SendAccountInvitationRequestBodyParams): Promise<IAccountInvitation> {
        const response = await this.requestWithContext<IAccountInvitation>({
            method: 'post',
            url: this.makeAPIPath('/send_invitation'),
            data
        });

        return response.data;
    }
}