export enum AuthStatus {
    Authorizing,
    LoggedOut,
    LoggedIn
}

export enum AuthRole {
    ROOT_ADMIN = 'ROOT_ADMIN',
    SUPER_ADMIN = 'SUPER_ADMIN',
    ADMIN = 'ADMIN',
    USER = 'USER',
    READ_ONLY = 'READ_ONLY',
    MILKSHAKE_ADMIN = 'MILKSHAKE_ADMIN',
    VIEW_AS = 'VIEW_AS',
    READ_ONLY_ADMIN = 'READ_ONLY_ADMIN',
    VIEW_AS_STRICT = 'VIEW_AS_STRICT'
}

export enum KeyCodes {
    Escape = 27,
    Enter = 13,
    Down = 40,
    Up = 38
}