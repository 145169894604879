import {define, singleton} from '@injex/core';
import EntityRequestAdapter, {ListResults} from '../../../stdlib/EntityRequestAdapter';
import IPodcastEpisode from '../interfaces/IPodcastEpisode';

@define('podcastAPI')
@singleton()
export class podcastAPI extends EntityRequestAdapter {
    protected basePathName: string = '';

    protected get apiEndpoint(): string {
        return this.env.contentAPI;
    }

    protected get useAuthorizationHeaders(): boolean {
        return false;
    }

    public async getEpispdes(): Promise<ListResults<IPodcastEpisode>> {
        const response = await this.requestWithContext<ListResults<IPodcastEpisode>>({
            method: 'get',
            url: '/public/podcast/episodes'
        });

        return response.data;
    }
}