import {computed, makeObservable, observable} from 'mobx';
import {all, any} from '../../../../stdlib/assert';
import {get} from '../../../../stdlib/utils';
import {TableColumn} from './utils';

export type ColumnMetadata = {
    key: string;
    width: number;
};

export interface IColumnManagerOptions {
    persistKey?: string;
    defaultWidth?: number;
}

function defaultOptions(options: IColumnManagerOptions): IColumnManagerOptions {
    return {
        defaultWidth: 200,
        ...options
    };
}

export abstract class ColumnsManager<T> {
    public tableColumns: TableColumn<T>[];
    protected tableColumnsIndex: { [key: string]: TableColumn<T>; };
    @observable public columns: { [key: string]: ColumnMetadata; };
    @observable public search: { [key: string]: string; };
    @observable public filter: { [key: string]: string[]; };
    @observable public freeze: string;
    @observable public sort: string;
    @observable public sortDir: number;

    private _options: IColumnManagerOptions;
    private _persistTimer: number;

    protected abstract get data(): T[];

    constructor(options: IColumnManagerOptions) {
        makeObservable(this);

        this._options = defaultOptions(options);

        this.search = {};
        this.filter = {};
        this.freeze = '';
        this.sort = '';
        this.sortDir = 1;
    }

    public setColumns(tableColumns: TableColumn<T>[]) {
        if (this.tableColumns) {
            throw new Error('Table Columns already set.');
        }

        this.tableColumns = tableColumns;
        this.tableColumnsIndex = tableColumns.reduce<{ [key: string]: TableColumn<T> }>((result, column) => {
            result[column.key] = column;
            return result;
        }, {});

        this._loadFromStorage();

    }

    public resetSearch() {
        this.search = {};
    }

    public resetFilter() {
        this.filter = {};
    }

    public resetSort() {
        this.sort = '';
        this.sortDir = 1;
    }

    public resetFreeze() {
        this.freeze = '';
    }

    public resetAllMasks() {
        this.resetSearch();
        this.resetFilter();
        this.resetSort();
        this.resetFreeze();
    }

    public is(columnManager: ColumnsManager<T>): boolean {
        return this === columnManager;
    }

    private _loadFromStorage() {
        if (this._options.persistKey) {
            try {
                const fromStorage = JSON.parse(localStorage.getItem(this._options.persistKey));
                if (fromStorage) {
                    this.columns = fromStorage;
                } else {
                    throw new Error('no column storage');
                }
            } catch (e) {
                this.columns = this._createInitialColumnsState();
                this._persist();
            }
        } else {
            this.columns = this._createInitialColumnsState();
        }
    }

    private _persist() {
        if (this._options.persistKey) {
            window.clearTimeout(this._persistTimer);
            this._persistTimer = window.setTimeout(() => {
                const storageData = Object.keys(this.columns).reduce((result, colKey) => {
                    let width;
                    if ((width = this.getWidth(colKey)) && width !== this._options.defaultWidth) {
                        result[colKey] = {key: colKey, width};
                    }
                    return result;
                }, {});
                localStorage.setItem(this._options.persistKey, JSON.stringify(storageData));
            }, 500);
        }
    }

    private _createInitialColumnsState(): { [key: string]: ColumnMetadata; } {
        return this.tableColumns.reduce<{ [key: string]: ColumnMetadata }>((result, {key, defaultWidth}) => {
            result[key] = {key, width: defaultWidth};
            return result;
        }, {});
    }

    public resetAllColumns() {
        if (this._options.persistKey) {
            localStorage.removeItem(this._options.persistKey);
        }

        this.columns = this._createInitialColumnsState();

        this.resetAllMasks();
    }

    public getWidth(columnKey: string): number {
        return this.columns[columnKey]?.width || this._options.defaultWidth;
    }

    public setWidth(columnKey: string, width: number) {
        this.columns = {
            ...this.columns,
            [columnKey]: {
                ...this.columns[columnKey],
                width
            }
        };

        this._persist();
    }

    public getColumn(key: string): TableColumn<T> {
        return this.tableColumnsIndex[key];
    }

    public setSearch(column: TableColumn<T>, query: string) {
        if (!query) {
            delete this.search[column.key];
        } else {
            this.search = {
                ...this.search,
                [column.key]: query
            };
        }
    }

    public setFilter(column: TableColumn<T>, values: string[]) {
        if (!values.length) {
            delete this.filter[column.key];
        } else {
            this.filter = {
                ...this.filter,
                [column.key]: values
            };
        }
    }

    public setFreeze(column: TableColumn<T>, freezed: boolean) {
        this.freeze = freezed ? column.key : '';
    }

    public setSort(column: TableColumn<T>, sortDir: 1 | -1) {
        if (this.sort === column.key && sortDir === this.sortDir) {
            this.sort = '';
            return;
        }

        this.sort = column.key;
        this.sortDir = sortDir;
    }

    @computed public get maskItems(): T[] {
        const hasSearch = any(...Object.values<string>(this.search));
        const hasFilter = Array.prototype.concat(...Object.values<string[]>(this.filter)).length > 0;

        let results = this.data;

        if (hasSearch || hasFilter) {
            results = this.data.filter((item) => {
                let includeBySearch = !hasSearch;
                let includeByFilter = !hasFilter;

                if (hasSearch) {
                    const valid = [];
                    for (const key in this.search) {
                        const query = this.search[key].toLowerCase();
                        const column = this.tableColumnsIndex[key];
                        const searchKey = column.options.search.searchKey;

                        if (typeof searchKey === 'string') {
                            const value = get(item, searchKey)?.toLowerCase() ?? '';
                            valid.push(value.indexOf(query) > -1);
                        } else if (typeof searchKey === 'function') {
                            valid.push(searchKey(item, query));
                        } else {
                            valid.push(false);
                        }
                    }
                    includeBySearch = all(...valid);
                }

                if (hasFilter) {
                    const valid = [];
                    for (const key in this.filter) {
                        const filter = this.filter[key]; // selected filter values by user
                        const column = this.tableColumnsIndex[key]; // filtered table column configurations
                        const value = item[column.options.filter.filterKey]; // current table <T> item

                        let shouldInclude = false;
                        for (let i = 0, len = filter.length; i < len; i++) {
                            const predicate = filter[i];
                            const filterOption = column.options.filter.options.find((o) => typeof o === 'string' ? o === predicate : o.value === predicate);

                            if (typeof filterOption === 'string' || typeof filterOption.check !== 'function') {
                                shouldInclude = filter.includes(value);
                            } else {
                                shouldInclude = filterOption.check(item);
                            }

                            if (shouldInclude) {
                                break;
                            }
                        }

                        valid.push(shouldInclude);
                    }
                    includeByFilter = all(...valid);
                }

                return includeBySearch && includeByFilter;
            });
        }

        if (this.sort) {
            const sortKey = this.tableColumnsIndex[this.sort]?.options?.sort?.sortKey;
            if (sortKey) {
                if (typeof sortKey === 'string') {
                    results = results.slice().sort((itemA, itemB) => {
                        const compare = get(itemA, sortKey) > get(itemB, sortKey) ? 1 : -1;
                        return compare * this.sortDir;
                    });
                } else {
                    results = results.slice().sort((itemA, itemB) => {
                        const compare = sortKey(itemA) > sortKey(itemB) ? 1 : -1;
                        return compare * this.sortDir;
                    });
                }
            }
        }

        return results;
    }

    public getColumnSearchQuery(column: string): string;
    public getColumnSearchQuery(column: TableColumn<T>): string;
    public getColumnSearchQuery(column: any): string {
        const columnKey = column.key || column;
        return this.search[columnKey] || '';
    }

    public getColumnFilterValues(column: string): string[];
    public getColumnFilterValues(column: TableColumn<T>): string[];
    public getColumnFilterValues(column: any): string[] {
        const columnKey = column.key || column;
        return this.filter[columnKey] || [];
    }
}