import {alias, define, init, inject, singleton} from '@injex/core';
import {Injex} from '@injex/webpack';
import {computed, makeObservable, observable} from 'mobx';
import IDisposable from '../../../common/interfaces/IDisposable';
import DataQuery from '../../../common/models/DataQuery';
import {isBoolean} from '../../../stdlib/assert';
import Hook from '../../../stdlib/Hook';
import {RouterService} from '../../router/services/routerService.mdl';
import {AuthStatus} from '../../session/common/enums';
import {SessionManager} from '../../session/managers/sessionManager.mdl';
import {AccountsAPI} from '../api/accounts.mdl';
import {AccountOnboardingStatus} from '../common/enums';
import IAccountMetadata, {IAccount} from '../interfaces/IAccountMetadata';

export type AccountHooks = {
    metadataLoad: Hook<[metadata: IAccountMetadata]>;
    accountChange: Hook<[account: IAccount]>;
};

@define()
@singleton()
@alias('Disposable')
export class AccountManager implements IDisposable {
    @inject() private $injex: Injex;
    @inject() private sessionManager: SessionManager;
    @inject() private accountsAPI: AccountsAPI;
    @inject() private routerService: RouterService;

    @observable.ref public accounts: IAccount[];
    @observable public selectedAccountId: string;
    @observable.ref public selectedAccount: IAccount;
    @observable public userDismissOnBoardingAlert: boolean;

    @observable public activityNameByPublisherIds: Map<string, string>;
    public accountMetadata: DataQuery<IAccountMetadata>;
    public hooks: AccountHooks;

    constructor() {
        makeObservable(this);
        this.accounts = [];
        this.accountMetadata = new DataQuery<IAccountMetadata>(this._fetchAccountMetadata.bind(this));
        this.selectedAccountId = '';
        this.selectedAccount = null;
        this.activityNameByPublisherIds = new Map();
        this.userDismissOnBoardingAlert = Boolean(window.sessionStorage.getItem('obad'));
        this.hooks = {
            metadataLoad: new Hook(),
            accountChange: new Hook()
        };

    }

    @init()
    protected initialize() {
        this.sessionManager.hooks.statusChange.tap(this._onSessionStatusChange, null, this);
        this.sessionManager.hooks.loginSuccess.tapAsync(this._onLogin, null, this);
    }

    public toggleOnBoardingAlert(force?: boolean) {
        this.userDismissOnBoardingAlert = isBoolean(force) ? force : !this.userDismissOnBoardingAlert;
        window.sessionStorage.setItem('obad', String(this.userDismissOnBoardingAlert));
    }

    public selectAccount(accountId: string, reload?: boolean, softReload?: boolean) {
        if (accountId === this.selectedAccountId) {
            return;
        }

        this.sessionManager.accountsSdk.auth.setSelectedAccounts(accountId);

        this.selectedAccountId = accountId;
        this.selectedAccount = this.accounts.find((account) => account._id === accountId);

        this._populateActivityNameByPublisher();

        if (reload) {
            this.routerService.replace('/');
            window.location.reload();
        } else {
            this.hooks.accountChange.call(this.selectedAccount);
        }

        if (softReload) {
            this.routerService.push('/');
        }
    }

    private _populateActivityNameByPublisher() {
        for (const activity of this.selectedAccount.activities) {
            for (const publisherId of activity.publisherIds) {
                this.activityNameByPublisherIds.set(publisherId, activity.name);
            }
        }
    }

    private _onSessionStatusChange(status: AuthStatus) {
        if (status !== AuthStatus.LoggedIn) {
            this.userDismissOnBoardingAlert = false;
        }
    }

    private async _fetchAccountMetadata(): Promise<IAccountMetadata> {
        const accountMetadata = await this.accountsAPI.me();

        this.$injex.logger.debug('[ACCOUNT METADATA]', accountMetadata);

        return accountMetadata;
    }

    private async _onLogin() {
        await this.accountMetadata.fetch();
        this.accounts = this.accountMetadata.data.accounts;
        const [accontId] = this.sessionManager.accountsSdk.auth.selectedAccounts;
        this.selectAccount(accontId || this.accounts[0]._id);
        this.hooks.metadataLoad.call(this.accountMetadata.data);

        if ([AccountOnboardingStatus.Intro, AccountOnboardingStatus.CompanyLegal].includes(this.onboardingStatus)) {
            this.routerService.push('/onboarding');
        }
    }

    @computed public get onboardingStatus(): AccountOnboardingStatus {
        return this.selectedAccount?.onboardingStatus;
    }

    public dispose(): void {
        this.accounts = [];
        this.accountMetadata.reset();
        this.selectedAccountId = '';
        this.selectedAccount = null;
    }
}